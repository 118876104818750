.rnc {
  display: flex;
  flex-direction: column;

  width: 30%;

  /* background-color: #eee; */
  border-radius: 1px;
  padding: 10px;
  box-sizing: border-box;
}

.rnc-row {
  display: flex;
  /* align-items: stretch; */
  margin: 0 0 10px 0;
  width: "2px";
}
.rnc-column {
  display: flex;
  width: "2px";
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 0 10px;
}

.rnc-canvas {
  /* box-sizing: border-box; */
  background-color: #fff;
  /* border-radius: 4px; */
}

.rnc-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: #fff;
  color: inherit;
  border: none;
  padding: 0;
  width: 25px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 4px;
}

.rnc-button svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.rnc-input {
  border: none;
  padding: 10px 6px;
  font-size: inherit;
  font-family: inherit;
}

#insitute1 {
  color: white !important;
}

#insitute1:after {
  color: white;
}

.headerCards {
  display: flex;
  /* margin-top: 65px; */
  position: fixed;
  top: 60px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  z-index: 999;
  overflow-x: auto;
  padding-top: 5px;
}

input#free-solo-2-demo {
  padding: 18.5px 14px;
}

.inner-tabs > div.MuiBox-root {
  padding: 0px !important;
}

.tab-container {
  margin-left: 147px;
}

.tab-container-master {
  margin-left: 310px;
}
.tab-container-master-drug {
  margin-left: 200px;
}

.tab-container .tab-accordian {
  border: 1px solid rgb(7, 160, 176);
  width: 100%;
}

.tab-accordian-details {
  padding: 0px !important;
}

.tab-accordian-details table th {
  padding: 10px;
}

.historytab > div {
  padding: 20px 0px;
}

::-webkit-scrollbar {
  background: transparent;
  overflow: visible;
  width: 10px;
  height: 5px;
  /* height: 200px !important; */
}

::-webkit-scrollbar-corner {
  background: #e4e6e9;
}

::-webkit-scrollbar-thumb {
  background-color: #a4aab2;
  background-clip: padding-box;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  box-shadow: inset 0px 0px 0px 1px #fff;
  -webkit-box-shadow: inset 0px 0px 0px 1px #fff;
}

::-webkit-scrollbar-track-piece {
  background: #f4f5f8;
}

.accordion-remove-icon {
  margin-right: 5px;
  color: white;
  margin-top: 10px;
}

.field-label {
  padding: 5px 0px 0px 0px;
  text-align: left;
}

.contact-field-label {
  padding: 16px 0px;
}

.sidebar-tabs-history {
  background-color: white;
  z-index: 100;
  position: fixed;
  margin-top: 35px;
  width: 160px !important;
}

.sidebar-tabs {
  background-color: white;
  z-index: 100;
  position: fixed;
  margin-top: 65px;
  width: 160px !important;
}

.sidebar-tabs-master {
  background-color: white;
  z-index: 100;
  position: fixed;
  margin-top: 10px;
  width: auto !important;
}

.vital-input-field {
  padding: 0px 15px;
}

@media (max-width: 959.95px) {
  .topbar-tabs {
    position: relative !important;
  }
  .sidebar-tabs,
  .sidebar-tabs-master {
    width: 100% !important;
    margin-top: 10px;
    position: relative !important;
  }
  /* #print img {
    width: 70% !important;
    height: max-content !important;
  } */

  .dropdown-menu-options {
    display: block !important;
  }
  .patient-barcode svg {
    width: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .vital-input-field {
    padding: 0px;
  }
}

.registration-panel > div > p.MuiTypography-body1 {
  margin-top: 200px !important;
}

select#insitute {
  width: 100%;
}

.report-table {
  min-width: 900px !important;
}

.table-header {
  min-width: 300px;
}
.report-table td {
  padding: 5px;
}

/* .MuiTabs-scrollable {
    margin-bottom: 0 !important;
  }

  .MuiTabs-scrollable::-webkit-scrollbar {
    display: block !important;
  } */

.consultation-tab .MuiTab-labelIcon {
  min-height: 48px;
}
