/* .App {
  text-align: center;
}
.MuiBox-root-4 {
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left: 1px solid;
  border-image: none 100% / 1 / 0 stretch;
  margin: 8px;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  background-color: rgb(255, 255, 255);
  border-top-style: solid;
  border-top-width: 1px;
}
.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-top: 0.5%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.MuiListItem-gutters {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.App {
  text-align: center;
}


element {

  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

}
/* .modal-open {overflow-y: scroll}  */
.MuiDialog-paperWidthSm {
  max-width: 731px ;
  border-radius: 23px ;
}
.MuiBox-root-4 {
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left: 1px solid;
  border-image: none 100% / 1 / 0 stretch;
  margin: 8px;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  background-color: rgb(255, 255, 255);
  border-top-style: solid;
  border-top-width: 1px;
}
.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-top: 0.5%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.MuiListItem-gutters {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.leaflet-container {
  width: 100%;
  height: 400px;
}

.MuiPickersClockPointer-pointer {
  left: calc(50% - 1px);
  width: 2px;
  bottom: 50%;
  position: absolute;
  background-color: #07A0B0 !important;
  transform-origin: center bottom 0px;
}
.e-btn.e-flat.e-primary:hover, .e-css.e-btn.e-flat.e-primary:hover {
  background-color: rgba(227, 22, 91, 0.04);
  border-color: transparent;
  color: #07A0B0 !important;
}
.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary {
  background-color: transparent;
  border-color: transparent;
  color: #07A0B0 !important;
}
.e-schedule .e-vertical-view .e-header-cells.e-current-day {
  color: #07A0B0;
}
.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
  background: #07A0B0;
  border: 1px solid #e8eaf6;
  border-radius: 2px;
  color: #fff;
  overflow: hidden;
  position: absolute;
  
}
.MuiPickersToolbar-toolbar {
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #07A0B0 !important;
}
/* .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px !important;
      padding-right: 0px !important;
} */
.e-calendar .e-content td.e-today.e-selected:hover span.e-day, .e-calendar .e-content td.e-selected:hover span.e-day, .e-calendar .e-content td.e-selected.e-focused-date span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  background-color: #07A0B0;
  color: #fff;
}
.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text, .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
    color: #07A0B0;
}
.e-schedule .e-vertical-view .e-clone-time-indicator, .e-schedule .e-vertical-view .e-current-time {
  color: #07A0B0;
}
.e-schedule .e-vertical-view .e-current-timeline {
  border-top: 1px solid #07A0B0;
}
.e-schedule .e-vertical-view .e-previous-timeline {
  border-top: 1px dotted #07A0B0;
}
.e-schedule .e-agenda-view .e-appointment {
  border-left: 3px solid #07A0B0;
  border-right: 0;
  cursor: default;
  padding: 0 8px;
}
.e-schedule .e-month-view .e-appointment {
  background: #07A0B0;
  border: 1px solid #e8eaf6;
  border-radius: 2px;
  color: #fff;
  cursor: default;
  display: -ms-flexbox;
  display: flex;
  height: 22px;
  overflow: hidden;
  position: absolute;
}

.MuiTab-textColorInherit.Mui-selected {
  /* opacity: 1; */
   background: #07A0B0 !important;
  color: white !important;
}
.MuiPickersClock-pin {
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  /* background-color: #07A0B0 !important; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 500;
}
.hnKQmi {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #07a0b0 !important;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  fill: #fff;
  height: 56px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 10px;
}
.itaQol {
  -webkit-animation: gZmgDU 0.3s ease forwards;
  animation: gZmgDU 0.3s ease forwards;
  background: #CAF7FB !important;
  border-radius: 18px 18px 18px 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: black !important;
  display: inline-block;
  font-size: 12px !important;
  max-width: 60% !important;
  margin: 0 0 10px 0;
  overflow: hidden;
  position: relative;
  padding: 12px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}
.jILTln {
  background: #CAF7FB !important;
  border: 0;
  border-radius: 22px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: black !important;
  display: inline-block;
  font-size: 12px !important;
  padding: 12px;
}
.htsnmY {
  -webkit-animation: gZmgDU 0.3s ease forwards;
  animation: gZmgDU 0.3s ease forwards;
  background: wheat !important;
  border-radius: 18px 18px 0 18px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: black !important;
  display: inline-block;
  font-size: 12px !important;
  max-width: 50%;
  margin: 0 0 10px 0;
  overflow: hidden;
  position: relative;
  padding: 12px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
}

 .bRskKb {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  background: #07a0b0 !important;
  bottom: 32px;
  border-radius: 100%;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  fill: #fff;
  height: 56px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  right: 32px;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.3s ease;
  -webkit-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  width: 56px;
  z-index: 999;
} 

.eyWAnO {
  -webkit-animation: gZmgDU 0.3s ease forwards;
  animation: gZmgDU 0.3s ease forwards;
  border-radius: 2% 50% 0 50% !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  height: 59px !important;
  min-width: -95px;
  padding: 3px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
  width: 40;
}

.kjWxFu {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  background: #07a0b0 !important;
  bottom: 32px;
  border-radius: 100%;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  fill: #fff;
  height: 56px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  right: 32px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.3s ease;
  -webkit-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  width: 56px;
  z-index: 999;
}

.fDgXbS {
  -webkit-animation: gZmgDU 0.3s ease forwards;
  animation: gZmgDU 0.3s ease forwards;
  background: #CAF7FB !important;
  border-radius: 0 18px 18px 18px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: black !important;
  display: inline-block;
  font-size: 12px !important;
  max-width: 50%;
  margin: -8px 0 10px 46px;
  overflow: hidden;
  position: relative;
  padding: 12px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;

}
.QkMEd {
  display: inline-block;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  padding: 20px !important;
}


