.chatbot {
    font-family: Roboto;
    border-radius: 10px;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    background: white;
    text-align: center;
    display: flex;
    flex-flow: column;
    width: 400px;
    overflow: hidden;
    
   
 }

  .header {
    padding: 13px;
    text-align: center;
    color: rgb(255, 255, 255);
    max-height: 58px;
    font-size: 16px;
    font-weight: 500;
    background-color: #07a0b0;
    
  }
  
  .messages {
    width: 100%;
    height: 400px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
  }
  
  .message-container {
    width: 100%;
  
  }
  
  /* .bot-message {
    float: left;
    padding: 15px 20px;
    margin: 5px;
    border-radius: 20px 20px 20px 1px;
    background: #CAF7FB ;
    color: black;
    min-width: 40px;
  } */
  .bot-message {
    float: left;
    padding: 10px 20px;
    margin: 54px;
        margin-top: 54px;
        margin-left: 54px;
    margin-top: 241px;
    margin-left: 241px;
    border-radius: 54px 54px 54px 54px;
    background: #CAF7FB;
    color: black;
    min-width: 28px;
    margin-left: 74px;
    margin-top: -3px;
}
  
  .user-message {
    float: right;
    padding: 15px 10px;
    margin: 10px;
    border-radius: 20px 20px 1px 20px;
    background: wheat;
    color: black;
    
  }
  
  .input {
    position: relative;
  }
  
  .input > form > input[type="text"] {
    /* font-family: monospace; */
    font-family: Roboto;
    font-size: 16px;
    border: 0;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #eee;
    box-shadow: none;
    box-sizing: border-box;
    opacity: 1;
    outline: none;
    padding: 16px 52px 16px 10px;
    width: 100%;
    -webkit-appearance: none;
  }
  
  .input > form > input:focus {
    outline: none;
  }
  
  .input > form > button {
    background-color: transparent;
    border: 0;
    border-bottom-right-radius: 10px;
    box-shadow: none;
    cursor: pointer;
    fill: #4a4a4a;
    opacity: 1;
    outline: none;
    padding: 14px 16px 12px 16px;
    position: absolute;
    right: 0;
    top: 0;
    
  }
  